.ContactUs {
    background-color: #282c35;
    padding-bottom: 60px;
}

.ContactUs h2 {
    line-height: 1.5;
    letter-spacing: -0.5px;
    font-weight: 300;
    font-size: 32px;
}

.ContactUs p {
    color: #727780;
    font-size: 16px;
}

.ContactUs .contactUsHeader {
    background-color: #21252c;
    padding: 60px;
    color: white;
    text-align: center;
}

.ContactUs .contactUsHeader h2 {
    font-size: 40px !important;
    font-weight: 400 !important;
    margin-bottom: 0px;
}

.ContactUs .contactUsInner {
    padding: 60px;
    color: white;
}

.ContactUs .contactUsInner .left h2 {
    font-size: 20px !important;
    font-weight: 500 !important;
    margin-bottom: 20px !important;
}

.ContactUs .contactUsInner .form-control {
    width: 100%;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.25);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    border-radius: 3px !important;
    padding: 0.5rem 1rem;
}

.ContactUs .btn {
    font-size: 11px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase;
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
    opacity: .75;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid rgba(255, 255, 255, 0.75);
    padding: 0.7rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 3px;
    transition: all 0.15s ease-in-out;
    text-decoration: none;
    margin-top: 25px;
}

.ContactUs .btn:hover {
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 1);
    color: white;
}

.ContactUs .rightSection p {
    color: #059faf !important;
    font-size: 14px !important;
}

@media (max-width:767px) {
    .ContactUs .contactUsInner {
        padding: 20px;
    }

    .ContactUs .contactUsHeader {
        padding: 40px;
    }


    .ContactUs .contactUsHeader h2 {
        font-size: 1.5rem !important;
    }

    .ContactUs .contactUsHeader p {
        font-size: 14px !important;
    }
}