.Sidebar {
    background-color: #121418;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.06);
    width: 280px;
}

.Sidebar>div {
    position: fixed;
    left: 0px;
    height: 100vh;
    background-color: #121418;
    width: 280px;
}

.Sidebar .top {
    background-color: #070809;
    text-align: center !important;
    /* padding: 25px 0px; */
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 25px 10px;
}

.Sidebar .top img {
    width: 43px;
}

.Sidebar .top h5 {
    font-size: 17px;
    font-weight: bold;
    margin-top: 10px;
    color: white;
}

.Sidebar .top h5 span {
    color: #0070c0;
}

.Sidebar ul {
    padding: 25px 20px;
    padding-left: 0px;
}

.Sidebar li {
    list-style: none;
}

.Sidebar ul li a {
    display: block;
    color: #585c64;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.Sidebar ul li a:hover {
    opacity: .6;
}

.Sidebar ul li .activeLink {
    color: #059faf;
}

.Sidebar ul li .activeLink:hover {
    opacity: 1;
}

/* .socialMedia {
    position: relative;
    height: auto;
} */

.socialMedia {
    position: absolute;
    /* left: 140px; */
    /* top: 100%; */
    left: 20px;
    bottom: 20px !important;
}

.socialMedia p {
    margin-bottom: 5px;
    font-size: 10px;
    color: #727780;
    text-transform: uppercase;
}

.socialMedia div {
    display: flex;
    gap: 5px;
    align-items: center;
}

.socialMedia i {
    color: #868e96 !important;
    font-size: 20px;
    transition: .2s all ease-in-out;
}

.socialMedia .hoverprimary:hover {
    color: #0866C6 !important;
}

.socialMedia .hoverred:hover {
    color: red !important;
}

.socialMedia .hoverpink:hover {
    color: #C13584 !important;
}

.socialMedia .hoverinfo:hover {
    color: #1695f5 !important;
}

/* @media (max-width: 767px) {
    .Sidebar>div,
    .Sidebar {
        width: 260px !important;
    }
} */