footer .top {
    background-color: #059faf;
    padding: 40px 60px;
}

footer h2 {
    line-height: 1.5;
    letter-spacing: -0.5px;
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 1.5rem;
    color: white;
}

footer p {
    color: #fff;
    font-size: 16px;
    opacity: .6;
}


footer .form-control {
    width: 100%;
    background-color: white;
    border-color: rgba(255, 255, 255, 0.25);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    border-radius: 3px !important;
    padding: 0.5rem 1rem;
}

footer button {
    font-size: 11px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase;
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
    opacity: .75;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid rgba(255, 255, 255, 0.75);
    padding: 0.7rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 3px;
    transition: all 0.15s ease-in-out;
    text-decoration: none;
    margin-top: 25px;
}

footer input {
    color: black !important;
}

footer button:hover {
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 1);
    color: white;
}

footer .top .left>div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

footer .top img {
    line-height: 45px;
    margin-top: 10px;
}

footer .bottom {
    padding: 30px;
    text-align: center;
    background-color: #070809;
}

footer .bottom p {
    color: #727780;
    font-size: 12px;
    margin-bottom: 0px !important;
}

@media (max-width:767px) {
    footer .top {
        padding: 40px 25px;
    }
}