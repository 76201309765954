.Header {
    height: 100vh !important;
    /* background-color: #121418; */
    /* width: fit-content !important; */
    background-image: url("../../assets/website_bkgd_book.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    /* display: flex;
    overflow-x: hidden !important; */
}

.Header>div {
    height: 100vh !important;
    padding: 60px;
    display: flex;
    align-items: flex-end;
}

.Header p {
    color: #727780;
    font-size: 16px;
}

.Header h1 {
    color: white;
    font-size: 2.5rem;
    letter-spacing: -1px;
}

.Header h4 {
    color: #059faf;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 32px;
}

.Header a {
    font-size: 11px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase;
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
    opacity: .75;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid rgba(255, 255, 255, 0.75);
    padding: 0.7rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 3px;
    transition: all 0.15s ease-in-out;
    text-decoration: none;
    margin-top: 25px;
}

.Header a:hover {
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 1);
}

.Header img {
    height: 86vh;
    position: relative;
    top: 30px;
    right: 30px;
    width: 40%;
    /* transform: rotate(-20deg); */
    transform: rotate3d(1, 1, -2, 30deg);
}

@media (max-width:1100px) {
    .Header {
        background-size: cover !important;
    }
}

@media (max-width:767px) {
    .Header>div {
        padding: 20px;
    }

    .Header {
        background-size: 123% 80vh !important;
        background-position: top !important;
        margin-top: -100px;
        height: fit-content !important;
        height: 80vh !important;
    }

    .Header>div {
        align-items: center;
    }
}