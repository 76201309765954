.About .intro {
    background-color: #282c35;
    width: 100%;
    padding: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.About h2 {
    line-height: 1.5;
    letter-spacing: -0.5px;
    font-weight: 300;
    font-size: 32px;
}

.About .team .teamHeader {
    background-color: #21252c;
    padding: 60px;
    color: white;
    text-align: center;
}

.About .team .teamHeader h2 {
    font-size: 40px !important;
    font-weight: 400 !important;
    margin-bottom: 0px;
}

.About p {
    color: #727780;
    font-size: 16px;
}

.About .teamRow {
    background-color: #282c35;
    padding: 80px 0px;
    color: white;
    text-align: center;
}

.About .teamRow .indRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 23px;
}

.About .teamRow .indRow>div {
    max-width: 300px;
    border: 1px solid white;
    border-radius: 4px;
    padding: 15px;
}

.About .teamRow h2 {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
}

.About .teamRow p {
    color: #727780;
    font-size: 16px;
}

.About .centeredStatements {
    display: flex;
    justify-content: center;
}

.About .teamRow ul {
    text-align: left;
    /* list-style: none; */
    margin-top: 60px;
}

.About .board {
    margin-top: 60px;
}

.About .board .author {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    padding: 60px;
    text-align: left;
}

.About .board .author h3 {
    font-size: 17px;
}

@media (max-width:1000px) {
    .About .author {
        flex-wrap: wrap;
    }
}

@media (max-width:767px) {
    .About .intro {
        padding: 40px;
    }

    .About .board .author {
        padding: 0px !important;
    }

    .About h2 {
        font-size: 1.25rem !important;
    }

    .About .team .teamHeader {
        padding: 40px !important;
    }

    .About .team .teamHeader h2 {
        font-size: 1.5rem !important;
    }

    .About .team .teamHeader p {
        font-size: 14px !important;
    }

    .About .teamRow {
        padding: 30px !important;
    }

    .About .teamRow .indRow>div {
        width: 100% !important;
        max-width: 100% !important;
        border: 1px solid white;
        border-radius: 4px;
        padding: 15px;
    }

    .About .board h1 {
        font-size: 1.5rem !important;
    }

    .About .board p {
        font-size: 14px !important;
    }
}