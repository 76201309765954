.Blog {
    background-color: #282c35;
    padding-bottom: 60px;
}

.Blog h2 {
    line-height: 1.5;
    letter-spacing: -0.5px;
    font-weight: 300;
    font-size: 32px;
}

.Blog p {
    color: #727780;
    font-size: 16px;
}

.Blog .blogHeader {
    background-color: #21252c;
    padding: 60px;
    color: white;
    text-align: center;
}

.Blog .blogHeader h2 {
    font-size: 40px !important;
    font-weight: 400 !important;
    margin-bottom: 0px;
}

.Blog .blogs {
    padding: 60px;
}

@media (max-width:767px) {
    .Blog .blogHeader {
        padding: 40px !important;
    }

    .Blog .blogHeader h2 {
        font-size: 1.5rem !important;
    }

    .Blog .blogHeader p {
        font-size: 14px !important;
    }

    .Blog .blogs {
        padding: 30px !important;
    }
}