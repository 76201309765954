@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0px;
  width: 100%;
  background-color: #D8DCE3;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
}

.App {
  overflow-x: hidden !important;
}

.App .rightMain {
  width: 100%;
}

.App .hamburger {
  transition: .3s all ease-in-out;
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 1000 !important;
}

.App .hamburger:focus {
  box-shadow: none !important;
}

.App .hamburger:focus i,
.App .hamburger:hover i {
  color: #059faf !important;
}

.App .hamburger i {
  font-size: 28px;
  color: white;
}

.App #moveIt {
  left: 286px;
}

.sidebarContainer {
  display: none;
}

.hamburger {
  display: block;
}

@media (min-width: 1100px) {
  .sidebarContainer {
    display: block;
  }

  .hamburger {
    display: none;
  }
}

@media (min-width: 1600px) {
  .App {
    width: 1600px !important;
    margin: 0px auto !important;
    position: relative;
  }

  .Sidebar>div {
    left: auto !important;
  }
}