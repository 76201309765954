.Events {
    background-color: #282c35;
    padding-bottom: 60px;
}

.Events h2 {
    line-height: 1.5;
    letter-spacing: -0.5px;
    font-weight: 300;
    font-size: 32px;
}

.Events .eventHeader {
    background-color: #21252c;
    padding: 60px;
    color: white;
    text-align: center;
}

.Events .eventHeader h2 {
    font-size: 40px !important;
    font-weight: 400 !important;
    margin-bottom: 0px;
}

.Events p {
    color: #727780;
    font-size: 16px;
}

.Events .tableCon {
    margin: 60px;
}

.Events table {
    margin-top: 60px;
}

.Events table {
    color: white;
}

@media (max-width:767px) {
    .Events .tableCon {
        margin: 10px;
    }

    .eventHeader {
        padding: 40px !important;
    }

    .Events .eventHeader h2 {
        font-size: 1.5rem !important;
    }

    .Events .eventHeader p {
        font-size: 14px !important;
    }

    .Events table {
        font-size: 14px !important;
    }
}