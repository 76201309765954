.BlogCard {
    color: #727780;
    margin-bottom: 60px;
}

.BlogCard h1 {
    color: white;
    font-size: 1rem;
    margin-bottom: 0px !important;
}

.BlogCard img {
    width: 100%;
    height: 420px;
    object-fit: cover;
}

.BlogCard .date {
    color: #059faf !important;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.BlogCard p {
    margin: 20px 0px;
}

.BlogCard .reaction {
    display: flex;
    gap: 43px;
    flex-wrap: wrap;
    align-items: center;
}

.BlogCard .reaction div {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #adb5bd !important;
    cursor: pointer;
    font-size: 12px;
}

.BlogCard .reaction div:hover {
    color: #e65c58 !important;
    transition: all 0.2s ease-in-out;
}

@media (max-width:1200px) {
    .BlogCard img {
        height: 300px;
    }

    .BlogCard .reaction {
        gap: 25px;
    }
}